import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const instance = applyCaseMiddleware(
  axios.create({
    // These defaults leverage the axios default XSRF Token
    // behavior and modifies it to work with CSRF tokens
    xsrfCookieName: 'POPUP-CSRF-TOKEN',
    xsrfHeaderName: 'X-CSRF-Token',
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  }),
);

const LOCALE_MAP = new Map(
  Object.entries({
    da: 'da_DK',
    de: 'de_DE',
    en: 'en_US',
    es: 'es_ES',
    fi: 'fi_FI',
    fr: 'fr_FR',
    hu: 'hu_HU',
    it: 'it_IT',
    ja: 'ja_JP',
    nl: 'nl_NL',
    nn: 'no_NO',
    pl: 'pl_PL',
    pt: 'pt_BR',
    ru: 'ru_RU',
    sv: 'sv_SE',
    tr: 'tr_TR',
  }),
);

export const getSiteLocaleForStripe = () =>
  // for whatever reason, the Norwegian locale is "nn" in the client info
  // but "no" in the stripe language codes... the rest all match
  window.CLIENT_INFO?.locale === 'nn' ? 'no' : window.CLIENT_INFO?.locale || 'en';

// the site "locale" is actually just the language code, PayPal needs the full locale
export const getSiteLocale = () => LOCALE_MAP.get(window.CLIENT_INFO?.locale) || 'en_US';
export const getSiteLocaleWithDash = () => getSiteLocale().replace('_', '-');
export const paymentMethodProvider = (offer) => {
  if (offer.paymentMethods?.includes('stripe')) return 'stripe';

  if (offer.paymentMethods?.includes('kajabi_payments')) return 'kajabi_payments';
  return null;
};

export const kajabiPaymentsOffer = (offer) => paymentMethodProvider(offer) === 'kajabi_payments';

export const postRequest = (endpoint, options) => instance.post(endpoint, options);
export const getRequest = (endpoint, options) => instance.get(endpoint, options);

export const decimalSeparator = () =>
  new Intl.NumberFormat(getSiteLocaleWithDash())
    .formatToParts(1.1)
    .find((part) => part.type === 'decimal').value;

export const localizedPrice = (currency, amount) =>
  new Intl.NumberFormat(getSiteLocaleWithDash(), {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })
    .formatToParts(amount)
    .map(({ type, value }) => {
      // The point of this is to deal with multiple currencies that use the $ symbol
      // normally something like $100.00 CAD would be formatted as CA$100.00 to differentiate it
      // from USD. In our UI, we always show the currency explicitly (e.g. $100.00 CAD), so the
      // extra CA$ is redundant and confusing.
      // There is a different currencyDisplay option (narrowSymbol) that takes care of this,
      // but alas, it wasn't supported in Safari until 14.1 (iOS 14.5), released April 26, 2021.
      // In order to maintain compatibility, we'll just handle $ ourselves.
      // Once we're able to require Safari 15 or newer, this can be removed.
      if (type === 'currency' && value.includes('$')) return '$';
      return value;
    })
    .join('');

export const localizedPriceFromMinorUnit = (currency, amount, exponent) =>
  localizedPrice(currency, majorUnitAmount(amount, exponent));

export const minorUnitAmount = (amount, exponent) => Math.round(amount * 10 ** exponent);

export const majorUnitAmount = (amount, exponent) => amount / 10 ** exponent;

// this due do some bonkers behavior of Javascript's Date object
// where it translates dates with dashes to UTC but not ones with slashes...
// since we only care about the date, the date with slashes will parsed as desired
// the one with dashes could possibly be off by a day
const dateFromString = (dateStr) => new Date(dateStr.replace(/-/g, '/'));

export const localizedDateWithYear = (date) =>
  new Date(date).toLocaleDateString(getSiteLocaleWithDash(), {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });

export const localizedDateWithYearFromString = (dateStr) =>
  localizedDateWithYear(dateFromString(dateStr));

export const localizedDateFromString = (dateStr) =>
  dateFromString(dateStr).toLocaleDateString(getSiteLocaleWithDash(), {
    month: 'long',
    day: 'numeric',
  });

export const featureEnabled = (featureFlag, activeFeaturesData) => {
  if (!activeFeaturesData) return false;
  return activeFeaturesData.includes(featureFlag);
};

// this is adapted from https://stackoverflow.com/questions/3942878
export const readableTextColor = (bgColor = '#FFFFFF') => {
  const darkColor = '#000000';
  const lightColor = '#ffffff';
  const threshold = 0.28;
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uiColors = [r / 255, g / 255, b / 255];
  const c = uiColors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > threshold ? darkColor : lightColor;
};
