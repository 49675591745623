import * as yup from 'yup';
import { majorUnitAmount } from 'apps/commerce/popup_checkout/util';

export const emailRegex =
  // eslint-disable-next-line no-control-regex, no-useless-escape
  /((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))/i;

const Address = yup.object().shape({
  addressLine1: yup.string().required(),
  addressLine2: yup.string(),
  country: yup.string().required(),
  subdivision: yup.string().when('subdivisionRequired', {
    is: true,
    then: yup.string().required(),
  }),
  city: yup.string().required(),
  zip: yup.string().required(),
});

export const validationSchema = yup.object().shape({
  email: yup.string().when('member', {
    is: (member) => !member,
    then: yup.string().email().required().matches(emailRegex),
  }),
  name: yup.string().when('nameRequired', {
    is: true,
    then: yup.string().required(),
  }),
  phone: yup.string().when('phoneRequired', {
    is: true,
    then: yup.string().required(),
  }),
  address: yup.object().when('addressRequired', {
    is: true,
    then: Address.required(),
  }),
  priceOverride: yup.string().when('priceOverrideRequired', {
    is: true,
    then: yup.string().test('is-valid-pwyw-price', '', (value, ctx) => {
      if (ctx.parent.price.amount === 0) {
        // free pay what you want can be 0, or more than 0.50
        const isValid = value === '' || parseFloat(value) === 0 || parseFloat(value) >= 0.5;
        if (!isValid) {
          return ctx.createError({ message: 'form.free-pwyw-price-invalid' });
        }
      } else {
        // pay what you want must be greater than the minimum price
        const minPrice = majorUnitAmount(ctx.parent.price.amount, ctx.parent.price.exponent);
        if (value < minPrice) {
          return ctx.createError({ message: 'form.pwyw-price-too-low' });
        }
      }
      return true;
    }),
  }),
});

// I don't think this is actually being used to set the form default values.
export const initialValues = {
  email: '',
  name: '',
  priceOverride: '',
  address: {
    subdivisionRequired: false,
  },
};
