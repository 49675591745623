import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import da from './translations/da.json';
import de from './translations/de.json';
import en from './translations/en.json';
import es from './translations/es.json';
import fi from './translations/fi.json';
import fr from './translations/fr.json';
import hu from './translations/hu.json';
import it from './translations/it.json';
import ja from './translations/ja.json';
import nl from './translations/nl.json';
import nn from './translations/nn.json';
import pl from './translations/pl.json';
import pt from './translations/pt.json';
import ru from './translations/ru.json';
import sv from './translations/sv.json';
import tr from './translations/tr.json';

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    react: {
      useSuspense: false
    },
    // Using simple hardcoded resources
    resources: {
      da,
      de,
      en,
      es,
      fi,
      fr,
      hu,
      it,
      ja,
      nl,
      nn,
      pl,
      pt,
      ru,
      sv,
      tr
    },
    supportedLngs: ['da', 'de', 'en', 'es', 'fi', 'fr', 'hu', 'it', 'ja', 'nl', 'nn', 'pl', 'pt', 'ru', 'sv', 'tr'],
    lng: window.CLIENT_INFO?.locale || 'en'
  });

export default i18next;
