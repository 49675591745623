import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@kajabi/sage-react';

function EmailInput({ disabled, defaultValue }) {
  const [email, setEmail] = React.useState(defaultValue);
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue) {
      setValue('email', defaultValue, { shouldValidate: true });
    }
  }, []); // Run once only!

  const handleChange = (event) => {
    setEmail(event.target.value);
    setValue('email', event.target.value, { shouldValidate: true });
  };

  return (
    <Input
      inputType="email"
      label={t('form.email')}
      id="email"
      value={email}
      onChange={handleChange}
      disabled={disabled}
      hasError={errors && !!errors.email}
      message={errors && errors.email ? t('form.invalid_email') : null}
    />
  );
}

EmailInput.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};

EmailInput.defaultProps = {
  disabled: false,
  defaultValue: '',
};

export default EmailInput;
